<template>
    <div id="template-payment" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="title flex items-center uppercase">Payment</div>
            <div class="save flex items-center justify-center ml-auto" @click="tambah">
                <feather-icon icon="PlusIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <!-- <vs-table @selected="handleSelected" pagination search max-items="10" :data="data"> -->
            <vs-table pagination search max-items="10" :data="data">
                <template slot="thead">
                    <vs-th>No</vs-th>
                    <vs-th sort-key="uuid">ID Reservasi</vs-th>
                    <vs-th sort-key="name">Nama</vs-th>
                    <vs-th sort-key="email">Email</vs-th>
                    <vs-th sort-key="phone">Telepon</vs-th>
                    <vs-th sort-key="amount">Jumlah</vs-th>
                    <vs-th sort-key="created_at">Tanggal</vs-th>
                    <vs-th sort-key="status">Status</vs-th>
                    <vs-th sort-key="action">Aksi</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            {{indextr+1}}
                        </vs-td>

                        <vs-td :data="data[indextr].uuid">
                            {{data[indextr].uuid}}
                        </vs-td>

                        <vs-td :data="data[indextr].name">
                            <span v-html="data[indextr].name"/>
                        </vs-td>

                        <vs-td :data="data[indextr].email">
                            {{data[indextr].email}}
                        </vs-td>

                        <vs-td :data="data[indextr].phone">
                            <span v-html="data[indextr].phone"/>
                        </vs-td>

                        <vs-td :data="data[indextr].amount">
                            <span v-html="numberWithCommas(data[indextr].amount)"/>
                        </vs-td>

                        <vs-td :data="data[indextr].created_at">
                            <span v-html="data[indextr].created_at"/>
                        </vs-td>

                        <vs-td :data="data[indextr].status">
                            <span v-html="getStatus(data[indextr].status)"/>

                        </vs-td>

                        <vs-td>
                            <div class="flex">
                                <!-- <div class="mr-1">
                                    <vx-tooltip
                                    delay=".2s"
                                    text="Resend Email"
                                    position="bottom"
                                    >
                                        <vs-button
                                            size="small"
                                            color="primary"
                                            icon="icon-send"
                                            icon-pack="feather"
                                            @click="resendEmail(data[indextr].uuid)"
                                        >
                                            <feather-icon icon="CopyIcon" svg-classes="h-5 w-5"></feather-icon>
                                        </vs-button>
                                    </vx-tooltip>
                                </div> -->

                                <div>
                                        <vs-button
                                            size="small"
                                            color="dark"
                                            icon="icon-info"
                                            icon-pack="feather"
                                            @click="handleSelected(data[indextr])"
                                        >
                                            <!-- <feather-icon icon="CopyIcon" svg-classes="h-5 w-5"></feather-icon> -->
                                        </vs-button>
                                </div>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: [],
            }
        },
        mounted() {
            this.loadData();
        },
        watch: {

        },
        methods: {
            async loadData() {
                this.getDataMessage().then(resp => {
                    this.data = resp
                })
            },
            getDataMessage() {
                return new Promise((resolve, reject) => {
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/payment',
                            method: 'GET',
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            numberWithCommas(x) {
                // return "Rp " + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                const amount = x
                return isNaN(amount) ? 0 : `Rp ${new Intl.NumberFormat().format(amount)}`
            },
            handleSelected($id) {
                if ($id.id) {
                    this.$router.push('/detail-payment/' + $id.id)
                }
            },
            getStatus(x) {
                var str = '';
                if(x == 1){
                    str = 'Success'
                }
                else if(x == 2){
                    str = 'Pending'
                }
                else if(x == 3){
                    str = 'Unpaid/waiting'
                }else if(x == 6){
                    str = 'Challange'
                }else if(x == 7){
                    str = 'Expire'
                }else if(x == 9){
                    str = 'Fail'
                }else{
                    str = ''
                }
                return str;
            },
            tambah() {
                this.$router.push('/add-reservation')
            },
            resendEmail(reserve_no){
                this.$vs.loading()
                const send = {
                    uuid : reserve_no
                }
                this.$http({
                    url: 'v1/payment/resend',
                    method: 'POST',
                    data: send
                })
                .then(() => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        title: 'Success!',
                        text: 'Saved!',
                        color: 'success',
                        position: 'top-center'
                    })
                })
                .catch(err => {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: err.response ? err.response.data.message :
                            'Something wrong, ' + err,
                        color: 'danger',
                        position: 'top-center'
                    })
                    this.$vs.loading.close()
                })
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template-payment {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .primary {
                color: $primary;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }
        }
    }
</style>